import { render, staticRenderFns } from "./MemberMePreferences.vue?vue&type=template&id=783c4fae&scoped=true&"
import script from "./MemberMePreferences.vue?vue&type=script&lang=ts&"
export * from "./MemberMePreferences.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783c4fae",
  null
  
)

/* custom blocks */
import block0 from "./MemberMePreferences.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect});
