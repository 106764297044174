


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import { lang, setLocale } from '../i18n';
import axios from 'axios';

@Component({})
export default class MemberMePreferences extends Vue {
  private langValue: any;
  private langOptions: any = [
    {
      label: 'Français',
      value: 'fr',
    },
    {
      label: 'English',
      value: 'en',
    },
  ];

  public async created() {
    this._updateLang(lang);
  }

  private updateLang(key: string) {
    axios.patch(`/member/me`, { lang: key })
    .then((response: any) => {
      setLocale(key);
      this._updateLang(key);
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private _updateLang(value: string) {
    for (const entry of this.langOptions) {
      if (entry.value === value) {
        this.langValue = entry;
      }
    }
  }
}
